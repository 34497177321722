export const USER_ROLE = Object.freeze({
  Anonymous: 0,
  Student: 1,
  SchoolTeacher: 2,
  CenterAdmin: 3,
  SchoolAdmin: 4,
  PreStudent: 5,
  SystemAdmin: 6,
  CoursewareTeacher: 7,
  CenterTeacher: 8,
})

// 1：短信验证码 2：邮箱验证码 3：图片验证码
export const VALIDATE_TYPE = Object.freeze({
  Sms: 1,
  Email: 2,
  Img: 3,
})

// WEIBO(1, "微博登录"),WEIXIN(2, "微信登录"),QQ(3, "QQ登录"),HASH(4, "Hash登录"),WXAPP(5, "微信小程序"),APPLE_ID(6, "Apple ID"),ORG(7,qingshu),WX_ACCOUNT(8,weixin机构公众号);
export const SSN_TYPE = Object.freeze({
  WEIBO: 1,
  WEIXIN: 2,
  QQ: 3,
  HASH: 4,
  WXAPP: 5,
  APPLE_ID: 6,
  ORG: 7,
  WX_ACCOUNT: 8,
})

export const IDENTIFY_TYPE = Object.freeze({
  Name: 1,
  Phone: 2,
  Email: 3,
  IdCard: 4,
  QQ: 5,
  WeiXin: 6,
  Account: 7,
  AppleId: 8,
  thirdInfo: 9,
})

export interface ApiUserInfo {
  id: number
  name: string
  displayName: string
  gender: string
  phone: string
  email: string
  address: string
  identityCard: string
  photoUrl: string
  userType: number
  phoneValid: boolean
  pwdStrong: boolean
  nationality: string
  birthday: number
  ssnInfos: SsnInfo[]
  userSchools: UserSchool[]
}
export interface SsnInfo {
  ssnToken: string
  ssnType: number
  ssnNickName: string
  ssnPhotoUrl: string
}
export interface UserSchool {
  id: number
  name: string
  symbol: string
  category: number
  schoolUserId: number
  userRole: number
  studentNumber: string
  centerId: number
  centerName: string
  classId: number
  className: string
  parentOrganizationId: number
  organizationId: number
  organizationName: string
  organizationLogo: string
  organizationStatus: number
  isTrial: boolean
  trialEndTime: number
  majorId: number
  majorName: string
  levelName: string
  semesterId: number
  semesterName: string
  examTicketNum: string
  examNum: string
  enrollSemesterId: number
  enrollSemesterName: string
  learningStatus: number
  learningStatusName: string
  studentStatusConfirm: boolean
  studentStatusPhotoUrl: string
  studentStatusPhotoCheckStatus: number
}

export const LOGIN_TYPE = Object.freeze({
  NameLogin: 1,
  SmsLogin: 2,
  SsnLogin: 3,
})

export const ACCOUNT_USER_TYPE = Object.freeze({
  CommonQsUser: 0,
  SuperManager: 1,
  QsTeacher: 2,
  AnonymousUser: 3,
})

export const USER_AGREEMENT_URL = 'https://service.qsxt.info/qsxt_yhxy.html'

export const USER_PRIVACY_URL = 'https://service.qsxt.info/qsxt_yszc.html'

export const USER_AGREEMENT_TIP = "请先勾选同意用户协议和隐私政策"
